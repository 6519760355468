//
//
//
//
//
//
//
//

import { constructIllustration } from "@debionetwork/ui-icons";
export default {
  name: "maintenancePageLayout",
  props: {
    error: {
      type: [Object, Boolean],
      default: null
    }
  },
  data: () => ({
    constructIllustration
  })
};