//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bookIcon, alertIcon } from "@debionetwork/ui-icons";
import getEnv from "@/common/lib/utils/env";
export default {
  name: "NavigationDrawer",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: "auto"
    }
  },
  data: () => ({
    bookIcon,
    alertIcon,
    version: ""
  }),
  created() {
    this.version = "v".concat(getEnv("VUE_APP_VERSION"));
  },
  methods: {
    customClass(active) {
      if (active) {
        return "font-weight-bold sidebar-text primary--text activeButton";
      }
      return "font-weight-bold sidebar-text";
    },
    goToDashboard() {
      this.$router.push({
        path: "/genetic-analyst"
      });
    }
  }
};