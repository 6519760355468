import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { compareDate } from "@/common/lib/utils";
import { mapActions, mapMutations, mapState } from "vuex";
import { searchIcon, bellIcon, settingIcon, userIcon, daiIcon, debioIcon, usersIcon, logoutIcon, polkadotIcon, copyIcon } from "@debionetwork/ui-icons";
import localStorage from "@/common/lib/local-storage";
import { generalDebounce } from "@/common/lib/utils";
import { queryAccountBalance } from "@debionetwork/polkadot-provider";
import { setReadNotification } from "@/common/lib/api";
import { queryGetAssetBalance, queryGetAllOctopusAssets } from "@/common/lib/polkadot-provider/query/octopus-assets";
import getEnv from "@/common/lib/utils/env";
let timeout;
export default {
  name: "Navbar",
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    error: {
      type: [Object, Boolean],
      default: null
    }
  },
  data: () => ({
    compareDate,
    bellIcon,
    settingIcon,
    userIcon,
    daiIcon,
    debioIcon,
    usersIcon,
    logoutIcon,
    polkadotIcon,
    searchIcon,
    copyIcon,
    searchQuery: "",
    contentHover: false,
    loginStatus: false,
    notifReads: [],
    arrowPosition: "",
    balance: 0,
    walletAddress: "",
    activeBalance: 0,
    loading: false,
    menus: [{
      id: 1,
      icon: bellIcon,
      type: "notification",
      title: "Notifications",
      active: false
    }, {
      id: 2,
      icon: polkadotIcon,
      type: "polkadot",
      title: "Wallet",
      currency: "DBIO",
      active: false
    }],
    polkadotWallets: [{
      id: null,
      name: "debio",
      icon: "debio-logo",
      currency: "DBIO",
      unit: "ether",
      balance: 0,
      tokenId: ""
    }, {
      id: null,
      name: "usdt",
      icon: "tether-logo",
      currency: "USDTE",
      unit: "mwei",
      balance: 0,
      tokenId: ""
    }],
    octopusAsset: []
  }),
  created() {
    this.polkadotWallets.forEach(wallet => {
      if (wallet.name === "usdt") {
        wallet.tokenId = getEnv("VUE_APP_DEBIO_USDT_TOKEN_ID");
      }
    });
  },
  computed: {
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    ...mapState({
      walletBalance: state => state.substrate.walletBalance,
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      web3: state => state.web3Store.web3,
      lastEventData: state => state.substrate.lastEventData
    }),
    getActiveMenu() {
      return this.menus.find(menu => menu.active);
    },
    computeMouseLeave() {
      return this.getActiveMenu ? this.getActiveMenu.id - 1 : null;
    }
  },
  async mounted() {
    this.refetchBalance();
  },
  watch: {
    lastEventData(val) {
      if (!val) return;
      this.refetchBalance();
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations({
      setWalletBalance: "substrate/SET_WALLET_BALANCE"
    }),
    async handleNotificationRead(notif) {
      clearTimeout(timeout);
      if (notif.read) return;
      notif.read = true;
      this.notifReads.push(notif.id);
      timeout = setTimeout(async () => {
        await setReadNotification(this.notifReads);
      }, 2000);
    },
    async handleCopy(text) {
      await navigator.clipboard.writeText(text);
      this.$refs.polkadot.$el.querySelector("input").value = "Address Copied!";
      generalDebounce(() => {
        this.$refs.polkadot.$el.querySelector("input").value = this.$refs.polkadot.$attrs["data-wallet"];
      }, 1000);
    },
    handleAvatar() {
      this.handleHideDropdown(this.computeMouseLeave);
    },
    async handleHover(e, idx) {
      this.menus.forEach(menu => menu.active = false);
      const selectedMenu = this.menus[idx];
      if (selectedMenu.type === "polkadot") {
        this.walletAddress = this.wallet.address;
        this.activeBalance = Number(this.walletBalance).toFixed(3);
      }
      selectedMenu.active = true;
      const calculateFinalPosition = this.$refs.menu.getBoundingClientRect().width + 200;
      this.arrowPosition = "".concat(e.target.getBoundingClientRect().left - this.$refs.menu.offsetLeft + calculateFinalPosition, "px");
    },
    handleHideDropdown(idx) {
      if (idx === null) return;
      this.menus[idx].active = false;
    },
    async fetchWalletBalance() {
      try {
        const balanceNummber = await queryAccountBalance(this.api, this.wallet.address);
        this.setWalletBalance(balanceNummber);
        this.polkadotWallets[0].balance = this.walletBalance;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPolkadotBallance() {
      this.polkadotWallets.forEach(async w => {
        if (w && w.name !== "debio") {
          const octopusData = this.octopusAsset.find(data => data.tokenId === w.tokenId);
          w.balance = octopusData.data ? this.web3.utils.fromWei(octopusData.data.balance.replaceAll(",", ""), w.unit) : 0;
        }
      });
    },
    async getOctopusAsset() {
      this.octopusAsset = [];
      const assets = await queryGetAllOctopusAssets(this.api);
      for (let i = 0; i < assets.length; i++) {
        const tokenId = assets[i][0].toHuman()[0];
        const id = assets[i][1].toHuman();
        const data = await queryGetAssetBalance(this.api, id, this.wallet.address);
        const assetData = {
          id,
          data,
          name: tokenId.split(".")[0],
          tokenId
        };
        this.octopusAsset.push(assetData);
      }
      await this.fetchPolkadotBallance();
    },
    async refetchBalance() {
      try {
        await this.fetchWalletBalance();
        await this.getOctopusAsset();
      } catch (err) {
        console.error(err);
      }
    },
    exportKeystoreAction() {
      try {
        const keystore = localStorage.getKeystore();
        const address = localStorage.getAddress();
        const file = new Blob([keystore], {
          type: "text/json;charset=utf-8"
        });
        const downloadUrl = window.URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "".concat(address, ".json");
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        console.error(err);
      }
    },
    signOut() {
      localStorage.clear();
      this.clearAuth;
      this.loginStatus = false;
      this.$router.push({
        name: "sign-in"
      });
      const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
      accounts.forEach(a => {
        window.localStorage.removeItem(a);
      });
    }
  }
};